<template>
  <div class="item-wrapper">
    <div class="price-container">
      <div class="price title">
        <span class="discount" v-if="item.old_price != '0.00'"
          >{{ item.old_price }}
          <span class="currency">
            <img src="./../assets/coin.svg" class="img" /> </span
        ></span>
        <span
          >{{ item.price }}
          <span class="currency">
            <img src="./../assets/coin.svg" class="img" /> </span
        ></span>
      </div>
      <div class="desc">{{ item.category }}</div>
    </div>
    <div class="preview">
      <img
        @click="goToSliderItem(item)"
        :src="imgDomain + item.img_url"
        class="img"
      />
      <div v-if="tag" class="tag">
        <span>{{ tag }}</span>
      </div>
      <transition name="fade">
        <span class="title" v-if="addToCartChosenItem == item.id">Added</span>
      </transition>
      <div class="link-container">
        <a @click="$emit('addToCart', item, item.id)" class="link">
          <span>Add to</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.636"
            height="16.636"
            viewBox="0 0 16.636 16.636"
          >
            <g
              id="shopping-cart_1_"
              data-name="shopping-cart (1)"
              transform="translate(-1 -1)"
            >
              <path
                id="XMLID_232_"
                d="M17.619,5.729l-1.612,6.449a1.146,1.146,0,0,1-1.113.869H4.952a1.147,1.147,0,0,1-1.139-1.014L2.652,2.721H1.86A.86.86,0,0,1,1.86,1H3.4a.861.861,0,0,1,.852.743L4.7,5.016H17.062A.574.574,0,0,1,17.619,5.729Z"
                fill="#fff"
              />
              <path
                id="XMLID_240_"
                d="M7,25.721A1.721,1.721,0,1,0,8.721,24,1.723,1.723,0,0,0,7,25.721Z"
                transform="translate(-2.558 -9.806)"
                fill="#fff"
              />
              <path
                id="XMLID_243_"
                d="M22.442,25.721a1.721,1.721,0,1,0-1.721,1.721A1.723,1.723,0,0,0,22.442,25.721Z"
                transform="translate(-7.674 -9.806)"
                fill="#fff"
              />
            </g>
          </svg>
        </a>
        <a @click="goToSliderItem(item)" class="link-info">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.976"
            height="16.932"
            viewBox="0 0 8.976 16.932"
          >
            <path
              id="info"
              d="M1.326-3.264A13.827,13.827,0,0,1,1.87-6.783,14.226,14.226,0,0,1,2.958-9.792a2.855,2.855,0,0,1,.935-1.173,2.024,2.024,0,0,1,.884-.187,1.5,1.5,0,0,1,.85.238.649.649,0,0,1,.357.51,2.425,2.425,0,0,1-.391.952,15.265,15.265,0,0,0-1,2.652,11.311,11.311,0,0,0-.612,3.162q0,1.6,1.19,1.6Q6.6-2.04,8.6-4.352a.822.822,0,0,1,.663-.34,1.047,1.047,0,0,1,.714.289.886.886,0,0,1,.323.68,1.458,1.458,0,0,1-.374.867Q7.412.1,4.692.1A3.275,3.275,0,0,1,2.244-.833,3.322,3.322,0,0,1,1.326-3.264Zm5-10.132q-1.836,0-1.836-1.6a1.652,1.652,0,0,1,.6-1.343A2.292,2.292,0,0,1,6.6-16.83q1.7,0,1.7,1.53a1.818,1.818,0,0,1-.544,1.394A2,2,0,0,1,6.324-13.4Z"
              transform="translate(-1.326 16.83)"
              fill="#fff"
            />
          </svg>
        </a>
      </div>
    </div>
    <a @click="goToSliderItem(item)" class="title">{{ item.title }}</a>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: ["item", "addToCartChosenItem", "sliderIsClickble", "tag", "currency"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToSliderItem(item) {
      let self = this;
      setTimeout(() => {
        if (self.sliderIsClickble == true) {
          self.$emit("goToProduct", item);
        }
      }, 200);
    },
  },
};
</script>
