<template>
  <main class="main csgo-products-page page-inside">
    <div class="main-wrapper">
      <CompactSlider :list="$parent.newArrivals" />
      <section class="section hero-section sale-section product-list-section">
        <div class="wrapper">
          <div class="title">
            <router-link to="/home" class="back"></router-link>
            Sale
          </div>
          <div class="list products sale-products">
            <div
              class="item"
              v-for="(item, index) in $parent.saleProducts"
              :key="index"
            >
              <ProductCard
                :item="item"
                :tag="'Sale'"
                :addToCartChosenItem="addToCartChosenItem"
                :sliderIsClickble="true"
                @goToProduct="goToProduct"
                :currency="currency"
                @addToCart="addToCart"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import ProductCard from "../components/ProductCard.vue";
import CompactSlider from "../components/CompactSlider.vue";

export default {
  name: "Sale",
  props: ["addToCartChosenItem", "currency"],
  components: {
    ProductCard,
    CompactSlider,
  },
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
};
</script>
