<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="title">
            <router-link to="/csgo-products" class="back"></router-link>
            Cart
          </div>
          <div class="right">
            <div class="wrapper">
              <div class="title small">Contact information</div>

              <div class="input-container steam-input-container">
                <div class="desc">
                  <span class="red">*</span>Steam trade link:
                </div>
                <input
                  type="text"
                  placeholder="Trade link"
                  v-model="tradeUrl"
                />
              </div>

              <div class="input-container">
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms" />
                      <div class="checkbox"></div>
                      <span class="title">I agree with </span>
                      <a @click="$parent.goToPage('privacy')" class="title"
                        >privacy policy
                      </a>
                      <span class="title"> and </span>
                      <a @click="$parent.goToPage('terms')" class="title"
                        >terms and conditions</a
                      >
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="wrapper">
              <div class="row">
                <div class="desc">Total to pay:</div>
                <div class="title">
                  {{ $parent.totalSum }}
                  <span class="currency">
                    <img src="./../assets/coin.svg" class="img" />
                  </span>
                </div>
              </div>
              <div class="row" v-if="hasInsufficientCredits">
                <div class="insufficient-warning desc">
                  {{ $t("Insufficient Credits. Please top up your balance.") }}
                </div>
              </div>
              <button
                @click="pay()"
                :class="[
                  'button',
                  {
                    disabled:
                      !requiredFieldsAreFilled || hasInsufficientCredits,
                  },
                ]"
              >
                <span>{{ $t("Pay with Credits") }}</span>
              </button>
              <button
                v-if="hasInsufficientCredits"
                @click="topUpBalance()"
                class="button top-up"
              >
                <span>{{ $t("Top Up Balance") }}</span>
              </button>

              <transition name="fade">
                <div class="desc red" v-if="error">{{ $t(error) }}</div>
              </transition>
            </div>
          </div>
          <div class="left">
            <div class="product-list">
              <div
                class="item"
                v-for="(item, i) in $parent.cartContents"
                :key="i"
              >
                <div class="preview">
                  <img :src="imgDomain + item.item.img_url" class="img" />
                </div>
                <div class="title">{{ item.item.title }}</div>
                <div>
                  <div class="count">
                    <div
                      class="count__item minus"
                      @click="$parent.removeOneFromCart(item, item.item_id)"
                    ></div>
                    <input type="number" value="1" v-model="item.count" />
                    <div
                      class="count__item plus"
                      @click="$parent.addToCart(item.item, item.item_id)"
                    ></div>
                  </div>
                  <div class="price">
                    {{ item.item.price }}
                    <span class="currency">
                      <img src="./../assets/coin.svg" class="img"
                    /></span>
                  </div>
                </div>
                <div
                  class="delete"
                  @click="$parent.removeFromCart(item.item, item.item_id)"
                >
                  <img src="./../assets/close.svg" class="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
export default {
  name: "Cart",
  props: [],
  components: {},
  data: function () {
    return {
      imgDomain: "",
      countryOptions: [],
      countryOptionsId: [],
      name: "",
      surname: "",
      phone: "",
      steamId: "",
      country: "",
      countryId: "",
      city: "",
      address: "",
      postCode: "",
      terms: false,
      tradeUrl: "",
      error: "",
      balance: 0,
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
    this.getCounties();
    this.getBalance();
  },
  computed: {
    requiredFieldsAreFilled() {
      return this.tradeUrl && this.terms;
    },

    hasInsufficientCredits() {
      return parseFloat(this.balance) < parseFloat(this.$parent.totalSum);
    },
  },
  methods: {
    getBalance() {
      this.$http
        .get(process.env.VUE_APP_API + "user/profile")
        .then((res) => {
          this.balance = res.data.balance;
          this.steamId = "76561198341685360";
          this.tradeUrl = res.data.steam_trade_link;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
        });
    },
    topUpBalance() {
      this.$parent.openTopup();
    },
    pay() {
      if (this.hasInsufficientCredits) {
        this.topUpBalance();
        return;
      }

      let data = {
        steamId: this.steamId,
        tradeUrl: this.tradeUrl,
      };

      this.$http
        .post(process.env.VUE_APP_API + "purchase/product", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.error = "";

            this.$parent.getOrderHistory();
            this.$parent.getCartContents();
            this.$parent.getBalance();
            this.$router.push({ path: "/profile" });
          } else {
            this.error = res.data.message;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
      console.log(this.countryId);
    },
    submit() {
      let data = {
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        steamId: this.steamId,
        country: this.countryId,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
      };
      this.$http
        .post(process.env.VUE_APP_API + "orders/checkout", data)
        .then((res) => {
          console.log(res);
          window.location = res.data.redirect_url;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    getProfile() {
      this.$http
        .get(process.env.VUE_APP_API + "user/profile")
        .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    getCounties() {
      this.$http
        .get(process.env.VUE_APP_API + "countries")
        .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function (item) {
            return item["title"];
          });
          let newCountriesId = arr.map(function (item) {
            return item["id"];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
  },
};
</script>
