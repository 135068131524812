<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">
            <router-link to="/csgo-products" class="back"></router-link>
            My Profile
          </div>
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper">
                <div class="title small">Personal Information</div>
                <div>
                  <div class="input-container">
                    <div class="desc">
                      <span class="red">*</span> First name:
                    </div>
                    <input
                      type="text"
                      placeholder="First name"
                      v-model="name"
                    />
                  </div>
                  <div class="input-container">
                    <div class="desc"><span class="red">*</span> E-mail:</div>
                    <input type="email" placeholder="E-mail" v-model="email" />
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      <span class="red">*</span> Last name:
                    </div>
                    <input
                      type="text"
                      placeholder="Last name"
                      v-model="surname"
                    />
                  </div>
                  <div class="input-container">
                    <div class="desc"><span class="red">*</span> Phone:</div>
                    <input type="tel" placeholder="Phone" v-model="phone" />
                  </div>
                </div>
                <button class="button" @click="updateProfileData">
                  <span>Save</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="profileUpdateErrorMessaage">
                    {{ profileUpdateErrorMessaage }}
                  </div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="profileUpdateSuccessMessaage">
                    {{ profileUpdateSuccessMessaage }}
                  </div>
                </transition>
              </div>
              <div class="wrapper">
                <div class="title small">Change Password</div>
                <div class="input-container">
                  <div class="desc">
                    <span class="red">*</span> Current password:
                  </div>
                  <input
                    type="text"
                    placeholder="Current password"
                    v-model="curPass"
                  />
                </div>
                <div class="input-container long">
                  <div class="desc">
                    <span class="red">*</span> New password:
                  </div>
                  <input
                    type="text"
                    placeholder="New password"
                    v-model="newPass"
                  />
                </div>
                <div class="input-container long">
                  <div class="desc">
                    <span class="red">*</span> Confirm password:
                  </div>
                  <input
                    type="text"
                    placeholder="Confirm password"
                    v-model="repeatPass"
                  />
                </div>
                <div class="pass-rules">
                  <div class="desc bold">Password must contain:</div>
                  <div class="desc">
                    - At least 6 characters, 1 upper case letter (A-Z), 1 lower
                    case letter (a-z), 1 number (0-9)
                  </div>
                </div>
                <button class="button" @click="updatePassword">
                  <span>Save</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="passUpdateErrorMessaage">
                    {{ passUpdateErrorMessaage }}
                  </div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="passUpdateSuccessMessaage">
                    {{ passUpdateSuccessMessaage }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="profile-table" v-if="$parent.orderHistory">
            <div class="profile-table-wrapper profile-form-wrapper">
              <div class="title small">Your Orders</div>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>Date/time</td>
                      <td>Skin name</td>
                      <td>Total</td>
                      <td>Status</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                      <td>{{ item.id }}</td>
                      <td>{{ $parent.formatDate(item.created_at) }}</td>
                      <td>{{ item.title }}</td>
                      <td class="amount">
                        <span>
                          {{ item.sum }}
                          <img src="./../assets/coin.svg" class="img" />
                        </span>
                      </td>
                      <td
                        :class="[
                          { green: item.status == 'Accepted' },
                          { white: item.status == 'Processing' },
                        ]"
                      >
                        {{ item.status }}
                      </td>
                      <td>
                        <a
                          class="offer-modal-open"
                          @click="$emit('openOfferDetailsModal', item)"
                          >View</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "Profile",
  props: [],
  components: {},
  data: function () {
    return {
      email: "",
      name: "",
      surname: "",
      phone: "",
      profileUpdateSuccessMessaage: "",
      profileUpdateErrorMessaage: "",
      curPass: "",
      newPass: "",
      repeatPass: "",
      passUpdateSuccessMessaage: "",
      passUpdateErrorMessaage: "",
    };
  },
  mounted() {
    this.$http
      .get(process.env.VUE_APP_API + "user/profile")
      .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      });
  },
  methods: {
    openOfferDetailsModal() {
      this.$emit("openOfferDetailsModal");
    },
    updateProfileData() {
      let profileData = {
        email: this.email,
        name: this.name,
        phone: this.phone,
        surname: this.surname,
      };
      this.$http
        .put(process.env.VUE_APP_API + "user/profile", profileData)
        .then((res) => {
          if (res.data.status == "OK") {
            this.profileUpdateSuccessMessaage = "Success";
            setTimeout(() => {
              this.profileUpdateSuccessMessaage = "";
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.profileUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.profileUpdateErrorMessaage = "";
          }, 3000);
        });
    },
    updatePassword() {
      let passwordList = {
        cur_password: this.curPass,
        new_password: this.newPass,
        repeat_password: this.repeatPass,
      };
      this.$http
        .post(process.env.VUE_APP_API + "user/change-password", passwordList)
        .then((res) => {
          if (res.data.status == "OK") {
            this.passUpdateSuccessMessaage = "Success";
            (this.curPass = ""), (this.newPass = ""), (this.repeatPass = "");
            setTimeout(() => {
              this.passUpdateSuccessMessaage = "";
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.passUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.passUpdateErrorMessaage = "";
          }, 3000);
        });
    },
  },
};
</script>
