<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible" />
        </transition>
        <div
          class="mob menu-icon"
          @click="toggleMenu"
          :class="{ active: menuVisible }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="header-container">
          <Header
            :app-balance="appBalance"
            :isAuth="isAuth"
            :currency-code="currencyCode"
            :cartContents="cartContents"
            @openSignInModal="openSignInModal"
            @openSignUpModal="openSignUpModal"
            @openSaleSkinModal="openSaleSkinModal"
            @changeCurrency="changeCurrency"
            @logout="logout"
            v-if="
              isAuth ||
              (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')
            "
          />
        </div>
        <router-view
          @openOfferDetailsModal="openOfferDetailsModal"
          @formatDate="formatDate"
          :addToCartChosenItem="addToCartChosenItem"
          :currency="currency"
          @addToCart="addToCart"
          @goToProduct="goToProduct"
        />
        <div class="footer-container">
          <Footer
            v-if="
              isAuth ||
              (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')
            "
          />
        </div>
        <transition name="fade">
          <SignInModal
            v-if="signInModalIsVisible"
            @closeSignInModal="closeSignInModal"
            @openSignUpModal="openSignUpModal"
            @login="login"
            @forgotPass="forgotPass"
          />
          <SignUpModal
            v-if="signUpModalIsVisible"
            @closeSignUpModal="closeSignUpModal"
            @openSignInModal="openSignInModal"
            @registration="registration"
          />
        </transition>
        <transition name="fade">
          <OfferDetailsModal
            v-if="offerDetailsModalIsVisible"
            @closeOfferDetailsModal="closeOfferDetailsModal"
            :orderToView="orderToView"
          />
        </transition>
        <transition name="fade">
          <SaleSkinModal
            v-if="saleSkinModalIsVisible"
            @closeSaleSkinModal="closeSaleSkinModal"
          />
        </transition>
        <transition name="fade">
          <TopUpModal
            v-if="topupModalIsVisible"
            @setError="setError"
            @orderSubmit="orderSubmit"
          />
        </transition>

        <transition name="fade">
          <OrderModal
            v-if="orderModalIsVisible"
            :order-data="orderData"
            @setError="setError"
            @orderSubmit="orderSubmit"
          />
        </transition>
        <div class="modal-overlay cookies" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="desc">
              We're delighted you've stopped by hyperskins! To make your visit
              as enjoyable and tailored as possible, we employ cookies and
              similar technologies. Essential cookies, crucial for our website's
              core functionality, are activated by default and don't need your
              consent.
            </div>
            <div class="cookies-modal-btns">
              <button class="button" @click="acceptCookies">Understood</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";
import SignInModal from "./components/SignInModal.vue";
import SignUpModal from "./components/SignUpModal.vue";
import OfferDetailsModal from "./components/OfferDetailsModal.vue";
import SaleSkinModal from "./components/SaleSkinModal.vue";
import { loadScriptsFromString } from "@/utils/load_scripts";
import TopUpModal from "./components/TopUp.vue";
import OrderModal from "./components/OrderModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "app",
  components: {
    Header,
    Menu,
    Footer,
    SignInModal,
    SignUpModal,
    OfferDetailsModal,
    SaleSkinModal,
    TopUpModal,
    OrderModal,
  },
  data: function () {
    return {
      pageLoaded: false,
      menuVisible: false,
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      offerDetailsModalIsVisible: false,
      saleSkinModalIsVisible: false,
      nav: [
        {
          title: "Home",
          link: "/home",
        },
        {
          title: "CS:GO Products",
          link: "/csgo-products",
        },
        {
          title: "NEW Arrivals",
          link: "/new-arrivals",
        },
        {
          title: "Sale",
          link: "/sale",
        },
      ],
      isAuth: false,
      error: "",
      successMessage: "",
      orderHistory: [],
      orderToView: [],
      addToCartChosenItem: "",
      newArrivals: [],
      recommendedProducts: [],
      saleProducts: [],
      cartContents: [],
      totalSum: "0.00",
      textPageTitle: "",
      textPageHeader: "",
      textPageContent: "",
      productPageItem: "",
      similarProducts: "",
      imgDomain: "",
      textPageList: "",
      hostname: "",
      footerRequisites: "",
      footerSupportEmail: "",
      footerCopyright: "",
      cookesModalIsVisible: false,
      appBalance: "",
      currencyOptions: [],
      topupModalIsVisible: false,
      orderModalIsVisible: false,
      orderData: {},
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
      }
    },
  },
  methods: {
    orderSubmit(data, type) {
      // Store the original currency for verification
      const originalCurrency = data.currency;

      // Add payment type without modifying other fields
      const submitData = {
        ...data,
        paymentType: type,
      };

      if (submitData.currency !== originalCurrency) {
        console.error("Currency was modified! Fixing it back to original.");
        submitData.currency = originalCurrency;
      }

      this.$http
        .post(process.env.VUE_APP_API + "deposit/checkout", submitData)
        .then((res) => {
          if (res.data.status == "ERROR") {
            this.$router.push({ name: "PaymentFaliPage" });
            this.orderModalIsVisible = false;
          } else {
            window.location = res.data.redirect_url;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    openOrderModal(data) {
      this.topupModalIsVisible = false;
      this.orderModalIsVisible = true;
      document.body.style.overflow = "hidden";
      this.orderData = data;
    },
    closeOrderModal() {
      this.orderModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = "";
    },
    setError(error) {
      this.error = error;
    },
    openTopup() {
      this.topupModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeTopup() {
      this.topupModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = "";
    },
    getCurrencies() {
      this.$http
        .get(process.env.VUE_APP_API + "currencies")
        .then((res) => {
          const newCurrencies = res.data.payload.map(
            ({ code: text, value: id, symbol }) => ({
              text,
              id,
              symbol,
            })
          );
          this.currencyOptions = newCurrencies;

          if (localStorage.getItem("currency")) {
            this.currencyCode = localStorage.getItem("currency");
          } else {
            this.currencyCode = this.currencyOptions[0].text;
          }
          this.checkCurrency();
        })
        .catch(() => {});
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      this.currency = this.currencyOptions.find(
        (item) => item.text == this.currencyCode
      ).symbol;
    },
    ...mapActions("currency", [
      "fetchCurrencies",
      "changeCurrency",
      "fetchInternalCurrencySettings",
    ]),
    getBalance() {
      if (this.isAuth) {
        this.$http
          .get(
            process.env.VUE_APP_API +
              "user/profile?currency=" +
              this.currencyCode
          )
          .then((res) => {
            this.appBalance = res.data.balance;
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.openSignIn();
            }
          });
      }
    },
    setAppBalance(balance) {
      this.appBalance = balance;
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookesModalIsVisible = false;
    },
    getGoogleTagManagerSnippet() {
      this.$http
        .get(process.env.VUE_APP_API + "settings")
        .then((res) => {
          this.footerRequisites = res.data.payload.requisites;
          this.footerSupportEmail = res.data.payload.support_email;
          this.footerCopyright = res.data.payload.copyright;
          const snippet = res.data.payload.google_tag;
          if (snippet) {
            loadScriptsFromString(snippet);
          }
        })
        .catch((error) => {
          console.error("Error fetching Google Tag Manager snippet:", error);
        });
    },
    getDomainName(hostName) {
      return hostName.substring(
        hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1
      );
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    openSignInModal() {
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
    },
    openSignUpModal() {
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
    },
    clearError() {
      this.error = "";
    },
    closeSignUpModal() {
      this.signUpModalIsVisible = false;
      this.clearError();
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    openOfferDetailsModal(item) {
      this.orderToView = item;
      this.offerDetailsModalIsVisible = true;
    },
    closeOfferDetailsModal() {
      this.offerDetailsModalIsVisible = false;
      this.orderToView = {};
    },
    openSaleSkinModal() {
      this.saleSkinModalIsVisible = true;
    },
    closeSaleSkinModal() {
      this.saleSkinModalIsVisible = false;
    },
    login(data) {
      this.$http
        .post(process.env.VUE_APP_API + "login", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.isAuth = true;
            this.closeSignInModal();
            this.getOrderHistory();
            this.getCartContents();
          } else {
            this.error = res.data.message;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    forgotPass(data) {
      let self = this;
      this.$http
        .post(process.env.VUE_APP_API + "user/reset-password", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = "Success";
            this.error = "";
            setTimeout(() => {
              self.successMessage = "";
            }, 2000);
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
        });
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = "Password confirmation failed!";
      } else {
        this.$http
          .post(process.env.VUE_APP_API + "register", data)
          .then((res) => {
            if (res.data.status == "OK") {
              this.clearError();
              self.isAuth = true;
              self.closeSignUpModal();
            } else {
              console.log(res);
              this.error = res.data.message;
            }
          })
          .catch((res) => {
            console.log(res);
            this.error = res.response.data.message;
          });
      }
    },
    logout() {
      this.$http
        .post(process.env.VUE_APP_API + "logout")
        .then(() => {
          if (this.$route.name != "Home") {
            this.$router.push({ path: "/" });
          }
          this.isAuth = false;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getOrderHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "purchase/history")
        .then((res) => {
          console.log(res);
          this.orderHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, "0");
      }
      let hoursAndMinutes =
        padTo2Digits(objectDate.getHours()) +
        ":" +
        padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + " " + hoursAndMinutes;

      return format;
    },
    removeOneFromCart(item, id) {
      let self = this;

      let cartItem = {
        id: id,
        count: "1",
      };
      if (item.count > 1) {
        cartItem = {
          id: id,
          count: item.count - 1,
        };
      }
      this.$http
        .post(process.env.VUE_APP_API + "cart/add", cartItem)
        .then((res) => {
          self.cartContents = res.data.cart;
          this.getCartContents();
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    removeFromCart(item, id) {
      let self = this;

      let cartItem = {
        id: id,
      };

      this.$http
        .post(process.env.VUE_APP_API + "cart/remove", cartItem)
        .then((res) => {
          self.cartContents = res.data.cart;
          this.getCartContents();
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    addToCart(item, id) {
      if (!this.isAuth) {
        this.openSignInModal();
      } else {
        let self = this;

        let cartItem = {
          id: id,
          count: 1,
        };

        for (var i = 0; i < this.cartContents.length; i++) {
          if (this.cartContents[i].item_id == id) {
            let cartItemCount = this.cartContents[i].count;
            cartItem = {
              id: id,
              count: cartItemCount + 1,
            };
            break;
          }
        }

        this.$http
          .post(process.env.VUE_APP_API + "cart/add", cartItem)
          .then((res) => {
            this.getCartContents();
            self.addToCartChosenItem = cartItem.id;
            self.cartContents = res.data.cart;
            setTimeout(() => {
              self.addToCartChosenItem = "";
            }, 2000);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.openSignInModal();
            }
          });
      }
    },
    getNewArrivals() {
      this.$http
        .get(process.env.VUE_APP_API + "items/list?new=true")
        .then((res) => {
          this.newArrivals = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getRecommended() {
      this.$http
        .get(process.env.VUE_APP_API + "items/recommended")
        .then((res) => {
          this.recommendedProducts = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getSale() {
      this.$http
        .get(process.env.VUE_APP_API + "items/list?sale=true")
        .then((res) => {
          this.saleProducts = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getCartContents() {
      this.$http
        .get(process.env.VUE_APP_API + "cart/contents")
        .then((res) => {
          this.cartContents = res.data.cart;

          let total = 0;
          for (let i in this.cartContents) {
            let newPrice;
            if (this.cartContents[i].count > 1) {
              newPrice =
                this.cartContents[i].count *
                parseFloat(this.cartContents[i].item.price);
            } else {
              newPrice = parseFloat(this.cartContents[i].item.price);
            }
            total += newPrice;
          }
          this.totalSum = total.toFixed(2);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    goToProduct(item) {
      this.$router.push({
        path: "/products/" + item.id,
        params: { data: item.id },
      });
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.productPageItem = "";
      this.similarProducts = "";
      this.$http
        .get(process.env.VUE_APP_API + "items/" + id)
        .then((res) => {
          console.log(res);
          this.productPageItem = res.data.payload;
          this.similarProducts = res.data.payload.similar;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    goToPage(item) {
      let newItem;
      if (item == "privacy") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("privacy")) {
            newItem = this.textPageList[i].id;
          }
        }
      } else if (item == "terms") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("terms")) {
            newItem = this.textPageList[i].id;
          }
        }
      } else {
        newItem = item;
      }
      this.$router.push({
        path: "/pages/" + newItem,
        params: { data: newItem },
      });
      this.getTextPageContent(newItem);
      this.closeSignInModal();
    },
    getTextPageContent(id) {
      this.textPageTitle = "";
      this.textPageHeader = "";
      this.textPageContent = "";
      this.$http
        .get(process.env.VUE_APP_API + "static-pages/" + id)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getTextPages() {
      this.$http
        .get(process.env.VUE_APP_API + "static-pages")
        .then((res) => {
          this.textPageList = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
  },
  mounted: async function () {
    await this.fetchCurrencies();
    await this.fetchInternalCurrencySettings();
    this.cookiesModal();
    this.hostname = this.getDomainName(window.location.hostname);
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();

    self.$http
      .get(process.env.VUE_APP_API + "is-auth")
      .then((res) => {
        console.log("auth: " + res.data.authenticated);
        self.isAuth = res.data.authenticated;

        if (res.data.authenticated) {
          self.getOrderHistory();
          self.getCartContents();
        }
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.openSignInModal();
        }
      });

    self.getGoogleTagManagerSnippet();
    this.getSale();
    this.getRecommended();
    this.getNewArrivals();
    this.getTextPages();
  },
  computed: {
    ...mapGetters("currency", [
      "currentCurrency",
      "currencySymbol",
      "allCurrencies",
      "isInternalCurrencyEnabled",
      "internalCurrencyName",
      "internalCurrencySymbol",
      "internalCurrencyImage",
    ]),
    currencyCode: {
      get() {
        return this.currentCurrency;
      },
      set(value) {
        this.changeCurrency(value);
      },
    },
    currency() {
      return this.currencySymbol;
    },
  },
};
</script>
