<template>
  <div class="section slider-section">
    <div class="slider-container" v-if="list.length">
      <slick
        ref="slick"
        :options="slickCompact"
        @swipe="handleSwipe"
        class="slider-small products"
      >
        <div class="item" v-for="(item, index) in list" :key="index">
          <a
            @click="goToSliderItem(item)"
            :class="['item-wrapper', { disabled: !sliderIsClickble }]"
          >
            <div class="preview">
              <img :src="imgDomain + item.img_url" class="img" />
            </div>
            <div class="text">
              <div class="desc">{{ item.category }}</div>
              <div class="title">{{ item.title }}</div>
            </div>
          </a>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";

export default {
  name: "CompactSlider",
  props: ["list"],
  components: {
    Slick,
  },
  data: function () {
    return {
      imgDomain: "",
      sliderIsClickble: true,
      slickCompact: {
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        infinite: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1601,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 901,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(() => {
        self.sliderIsClickble = true;
      }, 300);
    },

    goToSliderItem(item) {
      let self = this;
      setTimeout(() => {
        if (self.sliderIsClickble == true) {
          self.$parent.goToProduct(item);
        }
      }, 200);
    },
  },
};
</script>
