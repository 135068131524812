<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <ul class="nav">
            <template v-for="(item, i) in $parent.nav">
              <li class="nav__item" :key="i">
                <router-link :to="item.link">
                  {{ item.title }}
                </router-link>
              </li>
            </template>
          </ul>
          <ul class="nav">
            <template v-for="item in $parent.textPageList">
              <li class="nav__item" :key="item.id">
                <a @click="$parent.goToPage(item.id)">
                  {{ item.title }}
                </a>
              </li>
            </template>
          </ul>
        </div>
        <div class="center">
          <a href="index.html" class="logo">
            <img src="./../assets/logo.svg" class="img" />
          </a>
          <div class="text">
            <div class="desc" v-if="$parent.footerRequisites">
              {{ $parent.footerRequisites }}
            </div>
          </div>
          <ul class="list">
            <li class="item">
              <img src="./../assets/visa.svg" class="img" />
            </li>
            <li class="item">
              <img src="./../assets/mc.svg" class="img" />
            </li>
            <li class="item">
              <img src="./../assets/pci.png" class="img" />
            </li>
          </ul>
          <div class="desc">
            The Cardholder himself is responsible for compliance with the rules
            of the copyright holder.
          </div>
          <div class="desc">
            Any trademarks used belong to their respective owners.
          </div>
        </div>
        <div class="right">
          <ul class="list">
            <li class="item" v-if="$parent.footerSupportEmail">
              <div class="title">Support:</div>
              <div class="desc">{{ $parent.footerSupportEmail }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="desc" v-if="$parent.footerCopyright">
          {{ $parent.footerCopyright }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
