var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal topup-modal"},[_c('div',{staticClass:"overlay",on:{"click":_vm.$parent.closeTopup}}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-wrapper"},[_c('img',{staticClass:"close",attrs:{"src":require("./../assets/close.svg")},on:{"click":_vm.$parent.closeTopup}}),_c('div',{staticClass:"form-fields"},[_c('div',{staticClass:"form-fields-wrapper"},[_c('div',{staticClass:"cart-up"},[_vm._m(0),_c('div',{staticClass:"topup-form"},[_c('label',{staticClass:"input-container"},[_c('div',{staticClass:"desc"},[_vm._v("Enter amount")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],attrs:{"type":"text","placeholder":"Enter amount","required":""},domProps:{"value":(_vm.amount)},on:{"input":[function($event){if($event.target.composing)return;_vm.amount=$event.target.value},_vm.allowOnlyNumbers]}}),_c('div',{staticClass:"desc desc-curr"},[_vm._v(" "+_vm._s(_vm.$parent.currency)+" ")])]),_c('label',{staticClass:"input-container",attrs:{"for":"select2-currency-select-container"}},[_c('div',{staticClass:"desc"},[_vm._v(" Currency "+_vm._s(_vm.curr)+" "+_vm._s(_vm.currencyOptions)+" ")]),(
                        _vm.curr && _vm.currencyOptions && _vm.currencyOptions.length > 1
                      )?_c('Select2',{staticClass:"select2 single currency-select",attrs:{"id":"select2-currency-select-container","placeholder":_vm.curr,"options":_vm.currencyOptions},on:{"select":function($event){return _vm.selectCurr($event)}},model:{value:(_vm.curr),callback:function ($$v) {_vm.curr=$$v},expression:"curr"}}):_vm._e()],1),_c('div',{staticClass:"cta-container"},[_c('div',{staticClass:"chekbox-container"},[_c('label',{staticClass:"chekbox-label"},[_c('div',{staticClass:"chekbox-label-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.terms),expression:"terms"}],attrs:{"type":"checkbox","name":"terms"},domProps:{"checked":Array.isArray(_vm.terms)?_vm._i(_vm.terms,null)>-1:(_vm.terms)},on:{"change":function($event){var $$a=_vm.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.terms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.terms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.terms=$$c}}}}),_c('div',{staticClass:"checkbox"}),_c('span',{staticClass:"title"},[_vm._v("I agree with ")]),_c('a',{staticClass:"title",on:{"click":function($event){return _vm.$parent.goToPage('privacy')}}},[_vm._v(" privacy policy")]),_c('span',{staticClass:"title"},[_vm._v(" and ")]),_c('a',{staticClass:"title",on:{"click":function($event){return _vm.$parent.goToPage('terms')}}},[_vm._v(" terms and conditions")])])])])])])]),_c('div',{staticClass:"cart-bottom"},[_c('button',{class:[
                    'button blue',
                    { disabled: !_vm.requiredRegisterFieldsAreFilled },
                  ],on:{"click":_vm.send}},[_c('span',[_vm._v("Pay")])]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.creditsToReceive)+" "),_c('img',{staticClass:"img",attrs:{"src":require("./../assets/coin.svg")}})])]),_c('transition',{attrs:{"name":"slide"}},[(_vm.$parent.error)?_c('div',{staticClass:"error-desc desc red"},[_vm._v(" "+_vm._s(_vm.$parent.error)+" ")]):_vm._e()])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-top"},[_c('div',{staticClass:"title"},[_c('b',[_vm._v("TOP UP BALANCE")])])])
}]

export { render, staticRenderFns }