<template>
	<div class="features-section">
		<ul class="list">
		<li class="item">
			<div class="icon">
			<img src="./../assets/shield.svg" class="img"/>
			</div>
			<div class="title">100% Secure</div>
			<div class="desc small">All transactions are thoroughly monitored, so your money and skins are safe.</div>
		</li>
		<li class="item">
			<div class="icon">
			<img src="./../assets/layer.svg" class="img"/>
			</div>
			<div class="title">Wide Choice</div>
			<div class="desc small">There are many variations of unique and high quality skins.</div>
		</li>
		<li class="item">
			<div class="icon">
			<img src="./../assets/discount.svg" class="img"/>
			</div>
			<div class="title">Best Prices</div>
			<div class="desc small">CS isn't about flash grenades and headshots, it's about looking beautiful. Cheap, fast, safe and 24/7.</div>
		</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Features',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
