<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="left">
            <div class="title big">Welcome to HyperSkins</div>
            <div class="desc">
              Browse all CS:GO skins, knives, gloves, cases, collections,
              stickers, music kits, and more. The Trinity shop is the best place
              to buy CS:GO skins in a simple manner.
            </div>
            <router-link to="csgo-products" class="button">
              <span>Shop Now</span>
            </router-link>
          </div>
        </div>
      </div>
      <CompactSlider :list="$parent.newArrivals" />
      <div class="section arrivals-section">
        <div class="top">
          <div class="title"><span>NEW</span> Arrivals</div>
          <router-link to="/new-arrivals" class="link">
            <span>View more</span>
          </router-link>
        </div>
        <div class="wrapper">
          <SlickItem
            v-if="$parent.newArrivals.length"
            :list="$parent.newArrivals"
            :tag="'New'"
            :addToCartChosenItem="addToCartChosenItem"
            @addToCart="addToCart"
            @goToProduct="goToProduct"
            :currency="currency"
          />
        </div>
      </div>
      <div v-if="dailyOffer.title" class="section daily-offer-section">
        <div class="wrapper" @click="$parent.goToProduct(dailyOffer)">
          <div class="title side-title">
            <span>Daily Offer</span>
          </div>
          <div class="preview" v-if="dailyOffer.img_url">
            <img :src="imgDomain + dailyOffer.img_url" class="img" />
          </div>
          <div class="right">
            <div class="title small">{{ dailyOffer.title }}</div>
            <div class="title price">
              {{ dailyOffer.price }}
              <span class="currency">{{ currency }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section arrivals-section recommended-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">Recommended</div>
            <router-link to="/recommend" class="link">
              <span>View more</span>
            </router-link>
          </div>
          <SlickItem
            v-if="$parent.recommendedProducts.length"
            :list="$parent.recommendedProducts"
            :tag="'Recommend'"
            :addToCartChosenItem="addToCartChosenItem"
            @addToCart="addToCart"
            @goToProduct="goToProduct"
            :currency="currency"
          />
        </div>
      </div>
      <div class="section more-offers-section">
        <div class="wrapper">
          <div class="left">
            <div class="title big">More than 1K offers</div>
            <ul class="list">
              <li class="item desc">
                There are many variations of skins to choose from
              </li>
              <li class="item desc">Cheap CS:GO skins at a discounted price</li>
              <li class="item desc">
                It is your chance to get Dragon lore and other epic items!
              </li>
            </ul>
            <router-link to="csgo-products" class="button">
              <span>View all offers</span>
            </router-link>
          </div>
          <div class="left">
            <img src="./../assets/more-offers.png" class="img" />
          </div>
        </div>
      </div>
      <div class="section arrivals-section sale-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">Sale</div>
            <router-link to="/sale" class="link">
              <span>View more</span>
            </router-link>
          </div>
          <SlickItem
            v-if="$parent.saleProducts.length"
            :list="$parent.saleProducts"
            :tag="'Sale'"
            :addToCartChosenItem="addToCartChosenItem"
            @addToCart="addToCart"
            @goToProduct="goToProduct"
            :currency="currency"
          />
        </div>
      </div>
      <Features />
    </div>
  </main>
</template>
<script>
import SlickItem from "../components/SlickItem.vue";
import Features from "../components/Features.vue";
import CompactSlider from "../components/CompactSlider.vue";

export default {
  name: "Home",
  props: ["addToCartChosenItem", "currency"],
  components: {
    SlickItem,
    Features,
    CompactSlider,
  },
  data: function () {
    return {
      imgDomain: "",
      dailyOffer: {},
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getDailyOffer();
  },
  methods: {
    getDailyOffer() {
      this.$http
        .get(process.env.VUE_APP_API + "items/daily")
        .then((res) => {
          console.log(res);
          this.dailyOffer = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
  },
};
</script>
