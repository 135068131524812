<template>
  <div class="modal topup-modal">
    <div class="overlay" @click="$parent.closeTopup" />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img
              src="./../assets/close.svg"
              class="close"
              @click="$parent.closeTopup"
            />
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="cart-top">
                    <div class="title">
                      <b>TOP UP BALANCE</b>
                    </div>
                  </div>
                  <div class="topup-form">
                    <label class="input-container">
                      <div class="desc">Enter amount</div>
                      <input
                        v-model="amount"
                        type="text"
                        placeholder="Enter amount"
                        @input="allowOnlyNumbers"
                        required
                      />
                      <div class="desc desc-curr">
                        {{ $parent.currency }}
                      </div>
                    </label>

                    <label
                      class="input-container"
                      for="select2-currency-select-container"
                    >
                      <div class="desc">
                        Currency {{ curr }} {{ currencyOptions }}
                      </div>
                      <Select2
                        id="select2-currency-select-container"
                        v-if="
                          curr && currencyOptions && currencyOptions.length > 1
                        "
                        v-model="curr"
                        class="select2 single currency-select"
                        :placeholder="curr"
                        :options="currencyOptions"
                        @select="selectCurr($event)"
                      />
                    </label>

                    <div class="cta-container">
                      <div class="chekbox-container">
                        <label class="chekbox-label">
                          <div class="chekbox-label-wrapper">
                            <input
                              v-model="terms"
                              type="checkbox"
                              name="terms"
                            />
                            <div class="checkbox" />
                            <span class="title">I agree with </span>
                            <a
                              class="title"
                              @click="$parent.goToPage('privacy')"
                            >
                              privacy policy</a
                            >
                            <span class="title"> and </span>
                            <a class="title" @click="$parent.goToPage('terms')">
                              terms and conditions</a
                            >
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cart-bottom">
                  <button
                    :class="[
                      'button blue',
                      { disabled: !requiredRegisterFieldsAreFilled },
                    ]"
                    @click="send"
                  >
                    <span>Pay</span>
                  </button>
                  <div class="desc">
                    {{ creditsToReceive }}
                    <img src="./../assets/coin.svg" class="img" />
                  </div>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Select2 from "vue3-select2-component";
export default {
  name: "TopUp",
  components: {
    Select2,
  },
  props: [],
  data: function () {
    return {
      amount: 5,
      name: "",
      surname: "",
      phone: "",
      country: "",
      selectedCountry: "",
      countryId: "",
      city: "",
      address: "",
      postCode: "",
      countryOptions: [],
      countryOptionsId: [],
      countries: [],
      terms: "",
      selectedCurrency: null,
      curr: null,
      currencyOptions: [],
    };
  },
  computed: {
    ...mapGetters("currency", [
      "allCurrencies",
      "convertToInternalCurrency",
      "isInternalCurrencyEnabled",
      "internalCurrencyName",
      "internalCurrencySymbol",
      "internalCurrencyImage",
    ]),
    currencies() {
      console.log(this.allCurrencies);
      return this.allCurrencies;
    },
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (this.terms) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },

    creditsToReceive() {
      if (!this.amount || !this.selectedCurrency) return "0.00";

      const credits = this.convertToInternalCurrency(
        this.amount,
        this.selectedCurrency.text
      );

      return credits.toFixed(2);
    },
  },
  mounted() {
    this.getCounties();
    // Get currencies and set default
    if (this.currencies && this.currencies.length > 0) {
      this.selectedCurrency = this.currencies[0];
      this.curr = this.selectedCurrency.text; // This is the key - set curr directly

      this.currencyOptions = this.currencies.map((currency) => ({
        id: currency.text,
        text: currency.text,
      }));
    }

    this.$http
      .get(process.env.VUE_APP_API + "user/profile")
      .then((res) => {
        this.phone = res.data.phone;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.countryId = res.data.country;
        this.city = res.data.city;
        this.address = res.data.address;
        this.postCode = res.data.zip;

        if (this.countries && this.countries.length && this.countryId) {
          this.setCountry();
        }
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignIn();
        }
      });
  },
  methods: {
    allowOnlyNumbers() {
      this.amount = this.amount.replace(/[^0-9.,]/g, "");

      // Split the input into integer and decimal parts
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.amount =
        decimalPart !== undefined
          ? `${integerPart}.${decimalPart}`
          : integerPart;
    },
    selectCurr(event) {
      // When the user selects a currency from the Select2 dropdown
      this.curr = event.text;

      // Find and set the matching currency in the standard dropdown
      this.selectedCurrency = this.currencies.find(
        (currency) => currency.text === this.curr
      );

      if (!this.selectedCurrency && this.curr) {
        // Fallback if currency not found but we have the currency text
        console.warn(`Currency ${this.curr} not found in available currencies`);
        this.selectedCurrency = { text: this.curr };
      }
    },
    setCountry() {
      let mapped = this.countries.map(({ id, title }) => ({
        id,
        title,
      }));
      this.countries = mapped;
      this.selectedCountry = this.countries.find(
        (item) => item.id == this.countryId
      );

      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == this.selectedCountry.name) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCounties() {
      this.$http
        .get(process.env.VUE_APP_API + "countries")
        .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function (item) {
            return item["title"];
          });
          let newCountriesId = arr.map(function (item) {
            return item["id"];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
          this.countries = arr;
          let mapped = this.countries.map(({ id, title }) => ({
            id,
            title,
          }));
          this.countries = mapped;

          if (this.countries && this.countries.length && this.countryId) {
            this.setCountry();
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
        });
    },
    send() {
      let data = {
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        amount: this.amount,
        country: this.selectedCountry.id,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
        currency: this.curr, // This must be the selected currency from dropdown
        credits: this.creditsToReceive,
      };

      this.$emit("orderSubmit", data, "card");
    },
  },
};
</script>
