<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../assets/logo.svg" class="img" />
          <img src="./../assets/logo-mini.png" class="img mob" />
        </router-link>
        <ul class="nav">
          <template v-for="(item, i) in $parent.nav">
            <li class="nav__item" :key="i">
              <router-link :to="item.link">{{ item.title }}</router-link>
            </li>
          </template>
        </ul>
      </div>
      <div class="right">
        <div v-if="$parent.isAuth" class="cash">
          <img src="./../assets/wallet.svg" class="img" />
          <div class="desc">
            {{ balance }}
            <span class="currency">
              <img src="./../assets/coin.svg" class="img" />
            </span>
          </div>
          <button class="desc" @click="$parent.openTopup">
            <img src="./../assets/plus.svg" class="img" />
          </button>
        </div>
        <router-link
          to="/cart"
          v-if="$parent.isAuth"
          :class="['icon', { gray: !cartContents.length }]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
          >
            <g
              id="shopping-cart_1_"
              data-name="shopping-cart (1)"
              transform="translate(-1 -1)"
            >
              <path
                id="XMLID_232_"
                d="M22.977,7.253l-2.132,8.528a1.515,1.515,0,0,1-1.472,1.149H6.227A1.516,1.516,0,0,1,4.72,15.59L3.184,3.276H2.138A1.138,1.138,0,0,1,2.138,1H4.176A1.138,1.138,0,0,1,5.3,1.983L5.9,6.31H22.241A.759.759,0,0,1,22.977,7.253Z"
                fill="#FA3757"
              />
              <path
                id="XMLID_240_"
                d="M7,26.276A2.276,2.276,0,1,0,9.276,24,2.279,2.279,0,0,0,7,26.276Z"
                transform="translate(-1.448 -5.552)"
                fill="#FA3757"
              />
              <path
                id="XMLID_243_"
                d="M23.552,26.276a2.276,2.276,0,1,0-2.276,2.276A2.279,2.279,0,0,0,23.552,26.276Z"
                transform="translate(-4.345 -5.552)"
                fill="#FA3757"
              />
            </g>
          </svg>
          <div v-if="cartContents.length" class="indicator">
            {{ cartContents.length }}
          </div>
        </router-link>
        <div class="button link-container dropdown-open" v-if="$parent.isAuth">
          <span>My profile</span>
          <div class="arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.808"
              height="10"
              viewBox="0 0 16.808 10"
            >
              <path
                id="Контур_195"
                data-name="Контур 195"
                d="M9.233,10.829a1.6,1.6,0,0,1-1.139-.469l-6.8-6.8A1.6,1.6,0,0,1,3.563,1.3l5.67,5.67L14.9,1.3a1.6,1.6,0,0,1,2.266,2.266l-6.8,6.8a1.6,1.6,0,0,1-1.133.469Z"
                transform="translate(-0.829 -0.829)"
                fill="#fff"
              />
            </svg>
          </div>
          <div class="dropdown">
            <div class="dropdown-wrapper">
              <div class="nav__item">
                <router-link to="/profile"> Overview </router-link>
              </div>
              <!-- <div class="nav__item">
                    <router-link to="sell-offers">
                      Sell Offers
                    </router-link>
                  </div> -->
              <div class="nav__item nav__item-logout">
                <a @click="logout">
                  <span>LogOut</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                  >
                    <path
                      id="log-out_1_"
                      data-name="log-out (1)"
                      d="M5,18a1,1,0,0,0,1,1h8a1,1,0,0,1,0,2H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3h8a1,1,0,0,1,0,2H6A1,1,0,0,0,5,6Zm4-6a1,1,0,0,1,1-1h8.586L17.293,9.707a1,1,0,0,1,1.414-1.414l3,3a1,1,0,0,1,0,1.414l-3,3a1,1,0,0,1-1.414-1.414L18.586,13H10A1,1,0,0,1,9,12Z"
                      transform="translate(-3 -3)"
                      fill="#fff"
                      fill-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="link-container" v-else>
          <div
            class="nav__item nav__item-signIn"
            @click="$emit('openSignInModal')"
          >
            <a>Sign In</a>
          </div>
          <div class="button modal-open" @click="$emit('openSignUpModal')">
            <span>Sign Up</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: ["cartContents", "isAuth", "currencyCode"],
  data: function () {
    return {
      balance: "0.00",
    };
  },
  watch: {
    isAuth() {
      this.getBalance();
    },
    appBalance() {
      this.getBalance();
    },
    currencyCode(newValue) {
      this.curr = newValue;
      this.getBalance();
    },
  },
  mounted() {
    this.curr = this.currencyCode;
    this.getBalance();
  },
  methods: {
    openSignInModal() {
      this.$emit("openSignInModal");
    },
    openSignUpModal() {
      this.$emit("openSignUpModal");
    },
    openSaleSkinModal() {
      this.$emit("openSaleSkinModal");
    },
    logout() {
      this.$emit("logout");
    },
    getBalance() {
      if (this.$parent.isAuth) {
        this.$http
          .get(process.env.VUE_APP_API + "user/profile")
          .then((res) => {
            this.balance = res.data.balance;
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.openSignInModal();
            }
          });
      }
    },

    changeCurrency(item) {
      this.$emit("changeCurrency", item);
    },
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text);
      localStorage.setItem("currency", event.text);
    },
  },
};
</script>
