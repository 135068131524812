var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['app ' + _vm.$route.name],attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.pageLoaded)?_c('div',{staticClass:"page",attrs:{"id":"page"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.menuVisible)?_c('Menu'):_vm._e()],1),_c('div',{staticClass:"mob menu-icon",class:{ active: _vm.menuVisible },on:{"click":_vm.toggleMenu}},[_c('span'),_c('span'),_c('span')]),_c('div',{staticClass:"header-container"},[(
            _vm.isAuth ||
            (!_vm.isAuth && _vm.$route.name != 'Profile' && _vm.$route.name != 'Cart')
          )?_c('Header',{attrs:{"app-balance":_vm.appBalance,"isAuth":_vm.isAuth,"currency-code":_vm.currencyCode,"cartContents":_vm.cartContents},on:{"openSignInModal":_vm.openSignInModal,"openSignUpModal":_vm.openSignUpModal,"openSaleSkinModal":_vm.openSaleSkinModal,"changeCurrency":_vm.changeCurrency,"logout":_vm.logout}}):_vm._e()],1),_c('router-view',{attrs:{"addToCartChosenItem":_vm.addToCartChosenItem,"currency":_vm.currency},on:{"openOfferDetailsModal":_vm.openOfferDetailsModal,"formatDate":_vm.formatDate,"addToCart":_vm.addToCart,"goToProduct":_vm.goToProduct}}),_c('div',{staticClass:"footer-container"},[(
            _vm.isAuth ||
            (!_vm.isAuth && _vm.$route.name != 'Profile' && _vm.$route.name != 'Cart')
          )?_c('Footer'):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.signInModalIsVisible)?_c('SignInModal',{on:{"closeSignInModal":_vm.closeSignInModal,"openSignUpModal":_vm.openSignUpModal,"login":_vm.login,"forgotPass":_vm.forgotPass}}):_vm._e(),(_vm.signUpModalIsVisible)?_c('SignUpModal',{on:{"closeSignUpModal":_vm.closeSignUpModal,"openSignInModal":_vm.openSignInModal,"registration":_vm.registration}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.offerDetailsModalIsVisible)?_c('OfferDetailsModal',{attrs:{"orderToView":_vm.orderToView},on:{"closeOfferDetailsModal":_vm.closeOfferDetailsModal}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.saleSkinModalIsVisible)?_c('SaleSkinModal',{on:{"closeSaleSkinModal":_vm.closeSaleSkinModal}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.topupModalIsVisible)?_c('TopUpModal',{on:{"setError":_vm.setError,"orderSubmit":_vm.orderSubmit}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.orderModalIsVisible)?_c('OrderModal',{attrs:{"order-data":_vm.orderData},on:{"setError":_vm.setError,"orderSubmit":_vm.orderSubmit}}):_vm._e()],1),(_vm.cookesModalIsVisible)?_c('div',{staticClass:"modal-overlay cookies"},[_c('div',{staticClass:"cookies-modal"},[_c('div',{staticClass:"desc"},[_vm._v(" We're delighted you've stopped by hyperskins! To make your visit as enjoyable and tailored as possible, we employ cookies and similar technologies. Essential cookies, crucial for our website's core functionality, are activated by default and don't need your consent. ")]),_c('div',{staticClass:"cookies-modal-btns"},[_c('button',{staticClass:"button",on:{"click":_vm.acceptCookies}},[_vm._v("Understood")])])])]):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }