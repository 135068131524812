import axios from "@/axios";

const state = {
  isLoading: false,
  currencies: [],
  currentCurrency: localStorage.getItem("currency") || "",
  internalCurrency: {
    enabled: false,
    name: "Credits",
    symbol: "CR",
    exchange_rate_eur: 0.93,
    image: ""
  },
};

const getters = {
  currentCurrency: (state) => state.currentCurrency,
  currencySymbol: (state) => {
    if (state.currentCurrency && state.currencies.length) {
      const currency = state.currencies.find(
        (curr) => curr.text === state.currentCurrency
      );
      return currency ? currency.symbol : "";
    }
    return "";
  },
  allCurrencies: (state) => state.currencies,
  
  // Internal currency getters
  isInternalCurrencyEnabled: (state) => state.internalCurrency.enabled,
  internalCurrencyName: (state) => state.internalCurrency.name,
  internalCurrencySymbol: (state) => state.internalCurrency.symbol,
  internalCurrencyImage: (state) => state.internalCurrency.image,
  internalCurrencyExchangeRate: (state) => state.internalCurrency.exchange_rate_eur,
  
  // Conversion helpers
  convertToInternalCurrency: (state) => (amount, currencyCode) => {
    if (!amount) return 0;
    
    // If the currency is EUR, use direct exchange rate
    if (currencyCode === 'EUR') {
      return parseFloat(amount) / state.internalCurrency.exchange_rate_eur;
    }
    
    // For other currencies, convert to EUR first then to internal currency
    const currency = state.currencies.find(curr => curr.text === currencyCode);
    if (!currency) return 0;
    
    // Convert to EUR then to internal currency
    const amountInEur = parseFloat(amount) * (currency.id || 1);
    return amountInEur / state.internalCurrency.exchange_rate_eur;
  },
  
  convertFromInternalCurrency: (state) => (amount, currencyCode) => {
    if (!amount) return 0;
    
    // If the currency is EUR, use direct exchange rate
    if (currencyCode === 'EUR') {
      return parseFloat(amount) * state.internalCurrency.exchange_rate_eur;
    }
    
    // For other currencies, convert from internal currency to EUR first then to target currency
    const currency = state.currencies.find(curr => curr.text === currencyCode);
    if (!currency) return 0;
    
    // Convert to EUR first
    const amountInEur = parseFloat(amount) * state.internalCurrency.exchange_rate_eur;
    
    // Then convert to target currency
    return amountInEur / (currency.id || 1);
  }
};

const actions = {
  fetchCurrencies: async ({ commit, dispatch }) => {
    try {
      commit("setLoading", true);
      const response = await axios.get(process.env.VUE_APP_API + "currencies");
      
      if (response.data.status === "OK") {
        const currencies = response.data.payload.map(
          ({ code: text, value: id, symbol }) => ({
            text,
            id,
            symbol,
          })
        );
        
        commit("setCurrencies", currencies);
        
        // If we have a stored currency, use it
        if (localStorage.getItem("currency")) {
          commit("setCurrentCurrency", localStorage.getItem("currency"));
        } else if (currencies.length > 0) {
          // Otherwise use the first currency
          commit("setCurrentCurrency", currencies[0].text);
        }
        
        // Fetch internal currency settings
        await dispatch("fetchInternalCurrencySettings");
        
        return currencies;
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
    } finally {
      commit("setLoading", false);
    }
  },
  
  fetchInternalCurrencySettings: async ({ commit }) => {
    try {
      const response = await axios.get(process.env.VUE_APP_API + "settings");
      
      if (response.data && response.data.payload && response.data.payload.internal_currency) {
        commit("setInternalCurrency", response.data.payload.internal_currency);
      }
    } catch (error) {
      console.error("Error fetching internal currency settings:", error);
    }
  },
  
  changeCurrency: ({ commit }, currencyCode) => {
    commit("setCurrentCurrency", currencyCode);
    return currencyCode;
  },
};

const mutations = {
  setLoading: (state, loading) => (state.isLoading = loading),
  setCurrencies: (state, currencies) => (state.currencies = currencies),
  setCurrentCurrency: (state, currencyCode) => {
    localStorage.setItem("currency", currencyCode);
    state.currentCurrency = currencyCode;
  },
  setInternalCurrency: (state, internalCurrency) => {
    state.internalCurrency = {
      ...state.internalCurrency,
      ...internalCurrency
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}; 