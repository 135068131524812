<template>
  <main class="main csgo-products-page page-inside">
    <div class="main-wrapper">
      <CompactSlider :list="$parent.newArrivals" />
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title">
            <router-link to="/home" class="back"></router-link>
            CS:GO Products
          </div>
          <div class="top">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="left">
                  <div class="select-container" v-if="sortOptions.length">
                    <div class="desc">Sort:</div>
                    <Select2
                      class="select2 single"
                      v-model="sort"
                      :options="sortOptions"
                      placeholder="Highest price first"
                      @select="selectSort($event)"
                    />
                  </div>
                  <div class="select-container" v-if="categoryOptions.length">
                    <div class="desc">Type:</div>
                    <Select2
                      class="select2 single"
                      v-model="category"
                      :options="categoryOptions"
                      placeholder="All types"
                      @select="selectCategory($event)"
                    />
                  </div>
                  <div class="select-container" v-if="qualityOptions.length">
                    <div class="desc">Quality:</div>
                    <Select2
                      class="select2 single"
                      v-model="quality"
                      :options="qualityOptions"
                      placeholder="All quality"
                      @select="selectQuality($event)"
                    />
                  </div>
                  <div class="select-container select-container-range">
                    <div class="desc">Price:</div>
                    <div class="val-container">
                      <div class="val min">
                        <input type="number" v-model="barMinValue" />
                        <div class="desc">{{ currency }}</div>
                      </div>
                      <div class="divider">-</div>
                      <div class="val max">
                        <input type="number" v-model="barMaxValue" />
                        <div class="desc">{{ currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="select-container select-container-reset">
                  <div class="reset" @click="resetFilters">
                    <img src="./../assets/refreshing.svg" class="img" />
                    <div class="desc">Reset Filters</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="list products" v-if="productList">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard
                  :item="item"
                  :addToCartChosenItem="addToCartChosenItem"
                  :sliderIsClickble="true"
                  @goToProduct="goToProduct"
                  :currency="currency"
                  @addToCart="addToCart"
                />
              </div>
            </div>
          </transition>
          <pagination
            v-if="totalProducts"
            v-model="activePage"
            :records="totalProducts"
            :per-page="12"
            @paginate="changePage"
          />
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Select2 from "vue3-select2-component";
import Pagination from "vue-pagination-2";
import ProductCard from "../components/ProductCard.vue";
import CompactSlider from "../components/CompactSlider.vue";

export default {
  name: "CsGoProducts",
  props: ["addToCartChosenItem", "currency"],
  components: {
    Select2,
    Pagination,
    ProductCard,
    CompactSlider,
  },
  data: function () {
    return {
      imgDomain: "",
      filtersIsVisible: false,
      sort: "desc",
      category: "",
      quality: "",
      activePage: 1,
      totalProducts: null,
      sortOptions: [
        {
          id: "desc",
          text: "Highest price first",
        },
        {
          id: "asc",
          text: "Lowest price first",
        },
      ],
      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 1200,
      productList: {},
    };
  },
  watch: {
    barMinValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    barMaxValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http
      .get(process.env.VUE_APP_API + "items/list?page=1&sort=desc&limit=12")
      .then((res) => {
        this.productList = res.data.payload;
        this.totalProducts = res.data.meta.total;
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      });

    this.getCategoryOptions();
    this.getQualityOptions();
  },
  methods: {
    selectSort(event) {
      this.activePage = 1;
      this.sort = event.id;
      this.filterProducts();
    },
    selectCategory(event) {
      this.activePage = 1;
      this.category = event.text;
      this.filterProducts();
    },
    selectQuality(event) {
      this.activePage = 1;
      this.quality = event.text;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = "All quality";
      this.category = "All types";
      this.categoryOptions = "";
      this.qualityOptions = "";
      this.getCategoryOptions();
      this.getQualityOptions();
      this.barMinValue = 0;
      this.barMaxValue = 1200;
      this.filterProducts();
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      let addCategory = "";
      if (this.category != "All types") {
        addCategory = "category=" + this.category;
      } else {
        addCategory = "";
      }

      let addQuality = "";
      if (this.quality != "All quality") {
        addQuality = "&quality=" + this.quality;
      } else {
        addQuality = "";
      }
      let addPrice =
        "&price_from=" + this.barMinValue + "&price_till=" + this.barMaxValue;

      let addSort = "&sort=" + this.sort;

      let addPage = "&page=" + this.activePage;

      this.$http
        .get(
          process.env.VUE_APP_API +
            "items/list?" +
            addCategory +
            addQuality +
            addPrice +
            addSort +
            addPage +
            "&limit=12"
        )
        .then((res) => {
          console.log(res);
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "categories")
        .then((res) => {
          const newCategories = res.data.payload.map(
            ({ id: id, title: text, url: url }) => ({
              id,
              text,
              url,
            })
          );
          newCategories.unshift({ id: 0, text: "All types", url: "" });
          this.categoryOptions = newCategories;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    getQualityOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newQuality = res.data.payload.qualities;
          newQuality.unshift("All quality");
          this.qualityOptions = newQuality;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
};
</script>
