var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main csgo-products-page product-page page-inside"},[_c('div',{staticClass:"main-wrapper"},[_c('section',{staticClass:"section hero-section product-list-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"title"},[_c('router-link',{staticClass:"back",attrs:{"to":"/csgo-products"}}),_vm._v(" CS:GO Products ")],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.$parent.productPageItem)?_c('div',{staticClass:"product-preview"},[_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"preview-wrapper"},[_c('div',{staticClass:"preview"},[_c('transition',{attrs:{"name":"fade"}},[(
                        _vm.$parent.addToCartChosenItem ==
                        _vm.$parent.productPageItem.id
                      )?_c('span',{staticClass:"title"},[_vm._v("Added")]):_vm._e()]),_c('img',{staticClass:"img",attrs:{"src":_vm.$parent.imgDomain + _vm.$parent.productPageItem.img_url}})],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$parent.productPageItem.title))])])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"text-wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"desc"},[_vm._v("Type:")]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$parent.productPageItem.type))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"desc"},[_vm._v("Quality:")]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.$parent.productPageItem.quality)+" ")])]),_c('div',{staticClass:"row row-price"},[_c('div',{staticClass:"desc"},[_vm._v("Price:")]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$parent.productPageItem.price)+" "),_c('span',{staticClass:"currency"},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/coin.svg")}})])])]),_c('button',{staticClass:"button long",on:{"click":function($event){return _vm.$parent.addToCart(
                      _vm.$parent.productPageItem,
                      _vm.$parent.productPageItem.id
                    )}}},[_c('span',[_vm._v("Add to")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16.636","height":"16.636","viewBox":"0 0 16.636 16.636"}},[_c('g',{attrs:{"id":"shopping-cart_1_","data-name":"shopping-cart (1)","transform":"translate(-1 -1)"}},[_c('path',{attrs:{"id":"XMLID_232_","d":"M17.619,5.729l-1.612,6.449a1.146,1.146,0,0,1-1.113.869H4.952a1.147,1.147,0,0,1-1.139-1.014L2.652,2.721H1.86A.86.86,0,0,1,1.86,1H3.4a.861.861,0,0,1,.852.743L4.7,5.016H17.062A.574.574,0,0,1,17.619,5.729Z","fill":"#fff"}}),_c('path',{attrs:{"id":"XMLID_240_","d":"M7,25.721A1.721,1.721,0,1,0,8.721,24,1.723,1.723,0,0,0,7,25.721Z","transform":"translate(-2.558 -9.806)","fill":"#fff"}}),_c('path',{attrs:{"id":"XMLID_243_","d":"M22.442,25.721a1.721,1.721,0,1,0-1.721,1.721A1.723,1.723,0,0,0,22.442,25.721Z","transform":"translate(-7.674 -9.806)","fill":"#fff"}})])])])])])]):_vm._e()])],1)]),_c('div',{staticClass:"section arrivals-section recommended-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v("Recommended")]),_c('router-link',{staticClass:"link",attrs:{"to":"/recommend"}},[_c('span',[_vm._v("View more")])])],1),(_vm.$parent.similarProducts.length)?_c('SlickItem',{attrs:{"list":_vm.$parent.similarProducts,"tag":'We Recommend',"addToCartChosenItem":_vm.addToCartChosenItem,"currency":_vm.currency},on:{"addToCart":_vm.addToCart,"goToProduct":_vm.goToProduct}}):_vm._e()],1),_c('Features')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }