<template>
  <main class="main csgo-products-page product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title">
            <router-link to="/csgo-products" class="back"></router-link>
            CS:GO Products
          </div>
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <transition name="fade">
                      <span
                        class="title"
                        v-if="
                          $parent.addToCartChosenItem ==
                          $parent.productPageItem.id
                        "
                        >Added</span
                      >
                    </transition>
                    <img
                      :src="$parent.imgDomain + $parent.productPageItem.img_url"
                      class="img"
                    />
                  </div>
                  <div class="title">{{ $parent.productPageItem.title }}</div>
                </div>
              </div>
              <div class="text">
                <div class="text-wrapper">
                  <div class="row">
                    <div class="desc">Type:</div>
                    <div class="desc">{{ $parent.productPageItem.type }}</div>
                  </div>
                  <div class="row">
                    <div class="desc">Quality:</div>
                    <div class="desc">
                      {{ $parent.productPageItem.quality }}
                    </div>
                  </div>
                  <div class="row row-price">
                    <div class="desc">Price:</div>
                    <div class="title">
                      {{ $parent.productPageItem.price }}
                      <span class="currency">
                        <img src="./../assets/coin.svg" class="img" />
                      </span>
                    </div>
                  </div>
                  <button
                    class="button long"
                    @click="
                      $parent.addToCart(
                        $parent.productPageItem,
                        $parent.productPageItem.id
                      )
                    "
                  >
                    <span>Add to</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.636"
                      height="16.636"
                      viewBox="0 0 16.636 16.636"
                    >
                      <g
                        id="shopping-cart_1_"
                        data-name="shopping-cart (1)"
                        transform="translate(-1 -1)"
                      >
                        <path
                          id="XMLID_232_"
                          d="M17.619,5.729l-1.612,6.449a1.146,1.146,0,0,1-1.113.869H4.952a1.147,1.147,0,0,1-1.139-1.014L2.652,2.721H1.86A.86.86,0,0,1,1.86,1H3.4a.861.861,0,0,1,.852.743L4.7,5.016H17.062A.574.574,0,0,1,17.619,5.729Z"
                          fill="#fff"
                        />
                        <path
                          id="XMLID_240_"
                          d="M7,25.721A1.721,1.721,0,1,0,8.721,24,1.723,1.723,0,0,0,7,25.721Z"
                          transform="translate(-2.558 -9.806)"
                          fill="#fff"
                        />
                        <path
                          id="XMLID_243_"
                          d="M22.442,25.721a1.721,1.721,0,1,0-1.721,1.721A1.723,1.723,0,0,0,22.442,25.721Z"
                          transform="translate(-7.674 -9.806)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
      <div class="section arrivals-section recommended-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">Recommended</div>
            <router-link to="/recommend" class="link">
              <span>View more</span>
            </router-link>
          </div>
          <SlickItem
            v-if="$parent.similarProducts.length"
            :list="$parent.similarProducts"
            :tag="'We Recommend'"
            :addToCartChosenItem="addToCartChosenItem"
            @addToCart="addToCart"
            @goToProduct="goToProduct"
            :currency="currency"
          />
        </div>
        <Features />
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from "../components/SlickItem.vue";
import Features from "../components/Features.vue";
export default {
  name: "ProductPage",
  props: ["addToCartChosenItem", "currency"],
  components: {
    SlickItem,
    Features,
  },
  data: function () {
    return {};
  },
  created() {},
  mounted() {
    this.$parent.getProductPageContent(this.$route.params.id);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
  },
};
</script>
