<template>
  <div class="modal order-modal">
    <div class="overlay" @click="$parent.closeOrderModal"></div>
    <div class="wrapper">
      <div class="container form-fields">
        <img
          src="./../assets/close.svg"
          @click="$parent.closeOrderModal"
          class="close"
        />
        <div class="title small">Payment Method</div>
        <div class="button-list-container">
          <div class="button-list" v-if="paymentMethods">
            <div
              class="img-btn-container"
              v-for="(item, i) in paymentMethods"
              :key="i"
            >
              <a class="img-btn" @click="submit(item.endpoint_id)">
                <div class="logo-wrapper">
                  <img :src="$parent.appDomain + item.image" class="img" />
                </div>
                <div class="desc">
                  {{ item.title }}
                </div>
              </a>
            </div>
          </div>

          <div v-else class="desc">There are no payment methods available</div>

          <transition name="fade">
            <div class="desc red" v-if="$parent.error">{{ $parent.error }}</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderModal",
  props: {
    orderData: {
      type: [String, Object],
      required: true,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters("app", ["currency"]),
    paymentMethods() {
      return this.$parent.availablePaymentMethods;
    },
  },
  mounted() {},
  methods: {
    submit(type) {
      const paymentMethodKey = Object.keys(this.paymentMethods).find(
        (key) => this.paymentMethods[key].endpoint_id === type
      );
      this.$emit("orderSubmit", this.orderData, paymentMethodKey);
    },
  },
};
</script>
